// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-creatively-js": () => import("./../src/pages/portfolio/creatively.js" /* webpackChunkName: "component---src-pages-portfolio-creatively-js" */),
  "component---src-pages-portfolio-moe-assist-js": () => import("./../src/pages/portfolio/moe-assist.js" /* webpackChunkName: "component---src-pages-portfolio-moe-assist-js" */),
  "component---src-pages-portfolio-pbj-live-js": () => import("./../src/pages/portfolio/pbj-live.js" /* webpackChunkName: "component---src-pages-portfolio-pbj-live-js" */),
  "component---src-pages-portfolio-spiritune-js": () => import("./../src/pages/portfolio/spiritune.js" /* webpackChunkName: "component---src-pages-portfolio-spiritune-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

